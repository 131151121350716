.ysds-button {
    background: $primary-color;
    border-radius: 20px;
    display: inline-block;
    color: white;
    //font-weight: 800;
    line-height: 19px;
    text-align: center;
    //border: 1px solid $primary-color;
    letter-spacing: 1px;
    font-size: 12px;
    padding: 9px 15px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    font-family: HelveticaNowDisplay-XBd;
    appearance: none;

    &.big {
        font-size: 17px;
        padding: 14px 26px;
        &.lines {
            color: $primary-color;
            background: none;
            &.white {
                color: white;
                border: 1px solid white;
            }
        }
    }

    /* &.outlines {
        color: $primary-color;
        background: none;
        &.white {
            color: white;
            border: 1px solid white;
        }
    } */
    
}
