.text-and-image {
    main.brandArt &.accent {
        background-color: $color-dark-magenta;
    }

    main.brandLifeScience &.accent {
        background-color: $color-dark-blue;
    }

    main.brandSpecialtyLogistics &.accent {
        background-color: $color-dark-green;
    }

    main.brandEPL &.accent {
        background-color: $color-dark-red;
    }

    main.brandIT &.accent {
        background-color: $color-dark-green;
    }

    position: relative;
    &.darkblue, &.accent {
        background: $color-navy;
        .main-inner {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 100px;
            padding-bottom: 100px;
            color: white;
        }
    }
    .main-inner {
        margin: 100px 12.5%;
        padding: 0;
        .flex-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-items: stretch;
        }
        .text-container {
            padding-top: 30px;
            order: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            @media screen and (min-width: $medium) {
                width: 60%;
                order: 1;
                padding-bottom: 30px;
                padding-right: 40px;
            }
            .content {
                padding-top: 1px;
                position: relative;
            }
        }
        .image-container {
            width: 100%;
            height: 200px;
            order: 1;
            @media screen and (min-width: $small) {
                height: 300px;
            }
            @media screen and (min-width: $medium) {
                width: 40%;
                order: 2;
                height: auto;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.contain {
                height: auto;
                img {
                    object-fit: contain;
                }
            }
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
        margin-left: -$one-col;
        position: absolute;
    }


    &.mirrored {
        .image-container {
            order: 1;
        }
        .text-container {
            order: 2;
            padding-right: 0;
            @media screen and (min-width: $medium) {
                padding-left: 30px;
            }
            .line {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}
