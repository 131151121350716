.middle-hero.hero-centered {
    position: relative;
    background: $color-navy;
    &.dark {
        .background-image {
            display: block;
        }
        .main {
            background-color: rgba(0,0,0,0.7);
        }
    }
    &.light {
        .background-image {
            display: block;
        }
        .main {
            background-color: rgba(255, 255, 255, 0.7);
            .main-inner {
                color: black;
            }
        }
    }

    .brandLifeScience & {
        background-color: $color-dark-blue;
    }

    .brandArt & {
        background-color: $color-dark-magenta;
    }

    .brandSpecialLogistics & {
        background-color: $color-dark-green;
    }

    .brandEPL & {
        background-color: $color-dark-red;
    }

    .brandIT & {
        background-color: $color-dark-green;
    }

    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: top;
        background-size: cover;
        filter: saturate(0.5);
        display: none;
    }
    .main {
        position: relative;
        background-color: transparent;
        padding: 100px 0;
        @media sreen and (min-width: $medium) {
            padding: 150px 0;
        }
    }
    .main-inner {
        color: white;
        margin: 0 auto;
        padding: 0;
        @media screen and (min-width: $medium) {
            width: $four-col;
            padding: 0;
        }
        h2 {
            position: relative;
            font-family: HelveticaNowDisplay-XBd;
            font-size: 30px;
            line-height: 110%;
            @media screen and (min-width: $small) {
                font-size: 50px;
            }
        }
        h3 {
            font-size: 21px;
            font-family: HelveticaNowText-Regular;
            margin-top: 19px;
            line-height: 143%;
            position: relative;
            text-transform: none;
            font-weight: 100;
        }
        .ysds-button {
            margin-top: 20px;
            position: relative;
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
    }
    .full-width-image {
        margin-left: 1rem;
        margin-right: 1rem;
        @media screen and (min-width: $medium) {
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }
}
