.text-on-white {
    overflow: hidden;
    &.darkblue {
        background: $color-navy;
        .triangle { 
            background: $color-navy;
        }
        .main-inner {
            color: white;
            padding-bottom: 100px;
        }
    }
    &.grey {
        background: $grey;
        @media screen and (min-width: $medium) {
            background: none;
        }
        .triangle { 
            background: $grey;
        }
        .main-inner {
        }
    }
    &.line-left {
        .line {
            width: $three-col;
            margin-left: -$one-col;
        }
    }
    .bg-image {
        width: 100%;
        height: 300px;
        background-position: center;
        background-size: cover;
        right: 0;
        filter: saturate(0.5);
        @media screen and (min-width: $small) {
            height: 450px;
        }
        @media screen and (min-width: $medium) {
            position: absolute;
            height: 100%;
            width: $three-col;
            background-position: top;
            background-color: white;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            padding-top: 50px;
            @media screen and (min-width: $medium) {
                width: 70%;
                margin-left: 23%;
                padding-top: 0px;
            }
        }
    }
    .main {
        position: relative;
    }
    .triangle {
        background: white;
        clip-path: polygon(0 0, 75% 0, 62.5% 100%, 0% 100%);
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .main-inner {
        margin-left: 12.5%;
        margin-right: 12.5%;
        padding: 50px 0 100px;
        @media screen and (min-width: $medium) {
            width: $four-col;
            padding: 100px 0;
        }
        h2 {
            position: relative;
        }
        .content {
            padding-top: 20px;
            position: relative;
            h4 {
                font-size: 16px;
            }
            display: flex;
            flex-wrap: wrap;
            p:first-of-type {
                margin-top: 0;
            }
            .one-list {
                margin-right: 40px;
                margin-bottom: 30px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

    }
    .line {
        height: 1px;
        background: $primary-color;
        position: absolute;
    }
}
