.tracker {
    &.darkblue {
        background: $color-navy;
        .main-inner {
            margin: 0 auto;
            padding-top: 100px;
            padding-bottom: 100px;
            color: white;
        }
    }
    .main-inner {
        margin: $margin;
        h3 {
            width: 100%;
            text-align: center;
        }
        .tracker-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            input {
                width: 400px;
                border-radius: 5px;
                border: 1px solid grey;
                padding: 14px 15px;
                font-family: HelveticaNowText-Regular;
                font-size: 16px;
                @media screen and (min-width: $small) {
                    margin-right: 5px;
                }
                &:focus {
                    outline: none;
                }
            }
            button {
                background: $primary-color;
                border-radius: 5px;
                display: inline-block;
                color: white;
                line-height: 19px;
                text-align: center;
                border: 1px solid $primary-color;
                letter-spacing: 1px;
                white-space: nowrap;
                text-decoration: none;
                cursor: pointer;
                font-family: HelveticaNowDisplay-XBd;
                font-size: 17px;
                padding: 15px 26px;
                width: 100%;
                margin-top: 10px;
                @media screen and (min-width: $small) {
                    width: auto;
                    margin-top: 0;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .alert {
            margin-top: 8px;
            p {
                text-align: center;
            }
        }
        .timeline {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 50px;
            &:before {
                background-color: #eee;
                bottom: 0;
                content: " ";
                position: absolute;
                top: 0;
                width: 3px;
                @media screen and (min-width: $small) {
                    left: 50%;
                    margin-left: -1.5px;
                }
            }
            .box {
                border: 1px solid black;
                width: 90%;
                padding: 22px 20px 15px;
                position: relative;
                margin-bottom: 50px;
                margin-left: 10%;
                @media screen and (min-width: $small) {
                    width: calc(50% - 30px);
                    margin-left: 0;
                }
                &:nth-of-type(even) {
                    @media screen and (min-width: $small) {
                        margin-left: calc(50% + 30px);
                        &:after {
                            right: auto;
                            left: -45px;
                        }
                    }
                }
                &:after {
                    display: block;
                    content: '';
                    background: $primary-color;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: -42px;
                    @media screen and (min-width: $small) {
                        right: -47px;
                        left: auto;
                    }
                }
                h3 {
                    margin: 0;
                    text-align: left;
                }
                .date {
                    margin: 0;
                    margin-bottom: 20px;
                }
                .city {
                    margin: 0;
                    color: $primary-color;
                    font-family: HelveticaNowDisplay-XBd;
                }
                .country {
                    margin: 0;
                    font-size: 14px;
                    margin-top: -4px;
                }
            }
        }
    }
}
