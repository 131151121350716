.country-dropdown {
  height: 38px;
  width: 100%;
  color: $color-navy;
  border-radius: 3px;
  border: 1px solid white;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-top: 4px;
  font-size: 15px;
  font-family: HelveticaNowText-Regular, sans-serif;
}
