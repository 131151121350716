.agents {
    &.darkblue {
        background: $color-navy;
        .main {
            margin-top: 0;
            color: white;
            padding-top: 100px;
        }
        .main-inner {
            margin: 0 auto;
            padding-bottom: 100px;

        }
    }
    .main {
        position: relative;
        margin-top: 100px;
        h2 {
            margin-bottom: 10px;
            margin: 0 12.5%;
            padding-bottom: 3px;
            @media screen and (min-width: $small) {
                width: $four-col;
            }
        }
        .subtitle {
            padding-top: 15px;
            margin: 0 12.5%;
            @media screen and (min-width: $small) {
                width: $four-col;
            }
        }
        .line {
            height: 1px;
            background: $primary-color;
            position: absolute;
        }
    }
    .main-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px 12.5% 0;
        margin-bottom: 100px;
        .list {
            margin-bottom: 0;
            width: 100%;
            @media screen and (min-width: $small) {
                column-count: 3;
            }
        }
    }
}
