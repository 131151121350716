.cards  {
    &.alternative {
        background: $color-navy;
        .main {
            padding-top: 100px;
            padding-bottom: 50px;
            margin: 0 auto;
            h2 {
                color: white;
            }
            .one-card .text-container {
                padding: 28px 15px 20px;
            }
        }
        &.magenta {
            background-color: $color-light-magenta;
            h2 {
                color: $color-dark-magenta;
            }
            .main-inner h3 {
                border-color: $color-dark-magenta;
            }
        }
    }
    .main {
        position: relative;
        margin: 100px auto 50px;
        h2 {
            margin-left: 6.25%;
        }
        .line {
            height: 1px;
            background: $primary-color;
            position: relative;
            margin-bottom: 50px;
        }
    }
    .main-inner {
        display: flex;
        flex-wrap: wrap;
        padding: 0 6.25% 0;
        @media screen and (min-width: $small) {
            width: calc(100% + 30px);
        }
        .one-card {
            margin-bottom: 50px;
            text-decoration: none;
            color: black;
            background: white;
            @media screen and (min-width: $small) {
                margin-right: 30px;
                width: calc(50% - 30px);
            }
            @media screen and (min-width: $medium) {
                width: calc(25% - 30px);
            }
            img {
                height: 160px;
                background: $primary-color;
                filter: grayscale($image-grayscale-pct);
                width: 100%;
                object-fit: cover;
                @media screen and (min-width: $small) {
                    height: 200px;
                }
            }
            .text-container {
                padding: 28px 0px 0px;
            }
            h3 {
                border-bottom: 1px solid $primary-color;
                padding-bottom: 10px;
                margin-bottom: 12px;
                margin-top: 0;
            }
        }
        &.three {
            .one-card {
                @media screen and (min-width: $medium) {
                    width: calc(33.3333% - 30px);
                }
            }
        }
    }
    .brandArt & {
        .one-card h3 {
            border-color: $color-dark-magenta;
        }
        .ysds-button {
            border-color: $color-dark-magenta;
            &.lines {
                color: $color-dark-magenta;
            }
        }
    }

    .brandLifeScience & {
        .one-card h3 {
            border-color: $color-dark-blue;
        }
        .ysds-button {
            border-color: $color-dark-blue;
            &.lines {
                color: $color-dark-blue;
            }
        }
    }

    .brandSpecialLogistics & {
        .one-card h3 {
            border-color: $color-dark-green;
        }
        .ysds-button {
            border-color: $color-dark-green;
            &.lines {
                color: $color-dark-green;
            }
        }
    }

    .brandEPL & {
        .one-card h3 {
            border-color: $color-dark-red;
        }
        .ysds-button {
            border-color: $color-dark-red;
            &.lines {
                color: $color-dark-red;
            }
        }
    }

    .brandIT & {
        .one-card h3 {
            border-color: $color-dark-green;
        }
        .ysds-button {
            border-color: $color-dark-green;
            &.lines {
                color: $color-dark-green;
            }
        }
    }
}
