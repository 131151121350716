.offices {
    &.darkblue {
        background: $color-navy;
        .main {
            margin: 0;
            color: white;
        }
        .main-inner {
            margin: 0 auto;
            padding-bottom: 100px;
            color: black;
        }
    }
    .main {
        position: relative;
        margin-top: 100px;
        h2 {
            margin-bottom: 10px;
            margin: 0 12.5%;
            padding-bottom: 3px;
            @media screen and (min-width: $small) {
                width: $four-col;
            }
        }
        .subtitle {
            padding-top: 15px;
            margin: 0 12.5%;
            @media screen and (min-width: $small) {
                width: $four-col;
            }
        }
        .line {
            height: 1px;
            background: $primary-color;
            position: absolute;
        }
    }
    .main-inner {
        padding: 50px 12.5% 0px;
        margin-bottom: 100px;
        .flex-container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -50px;
            @media screen and (min-width: $small) {
                width: calc(100% + 20px);
            }
        }
        .one-card {
            margin-bottom: 50px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            overflow: hidden;
            width: 100%;
            background: white;
            @media screen and (min-width: $small) {
                width: calc(33.333% - 20px);
                margin-right: 20px;
            }
            img {
                height: 200px;
                background: $primary-color;
                filter: grayscale($image-grayscale-pct);
                width: 100%;
                object-fit: cover;
                object-position: center;
                @media screen and (min-width: $small) {
                    height: 150px;
                }
                @media screen and (min-width: $medium) {
                    height: 200px;
                }
            }
            .text-container {
                background: white;
                padding: 28px 15px 10px;
            }
            h3 {
                border-bottom: 1px solid $primary-color;
                padding-bottom: 4px;
                margin-bottom: 10px;
                margin-top: 0;
            }
        }
    }
}
