.black-diagonal {
    .main {
        position: relative;
    }
    .triangle {
        background: black;
        height: 100%;
        width: 100%;
        position: absolute;
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 75%);
        @media screen and (min-width: $small) {
            clip-path: polygon(0 0, 75% 0, 62.5% 100%, 0% 100%);
        }
    }
    .flex-container {
        display: flex;
        position: relative;
        color: white;
        flex-wrap: wrap;
        padding: 100px 0 0;
        @media screen and (min-width: $small) {
            padding: 100px 0;
        }
        @media screen and (min-width: $medium) {
            padding: 150px 0;
        }
    }
    .left {
        position: relative;
        width: 100%;
        padding-left: 12.5%;
        @media screen and (min-width: $small) {
            width: $two-col;
            padding-left: 0%;
            padding-top: 9%;
        }
        h2 {
            border-bottom: 1px solid $primary-color;
            div {
                font-size: 30px;
                margin: 0 0 10px;
                font-family: HelveticaNowDisplay-XBd;
                line-height: 110%;
            }
            @media screen and (min-width: $small) {
                text-align: right;
            }
        }
    }
    .image-container {
        width: 100%;
        text-align: center;
        padding: 0 12.5%;
        padding-top: 20px;
        @media screen and (min-width: $small) {
            width: 42%;
            padding: 0 30px;
            margin-right: auto;
        }
        img {
            width: 100%;
            height: 250px;
            object-position: top;
            display: inline-block;
            max-width: 100%;
            object-fit: contain;
            @media screen and (min-width: $small) {
                width: auto;
                height: auto;
            }
        }
    }
    .right {
        position: relative;
        width: 100%;
        padding-right: 12.5%;
        color: black;
        text-align: right;
        padding-top: 90px;
        @media screen and (min-width: $small) {
            width: $two-col;
            text-align: left;
            padding-top: 9%;
            padding-right: 0;
        }
        h2 {
            border-bottom: 1px solid $primary-color;
            div {
                font-size: 30px;
                margin: 0 0 10px;
                font-family: HelveticaNowDisplay-XBd;
                line-height: 110%;
            }
        }
        p {
            padding-left: 12.5%;
            line-height: 133%;
            margin-top: 14px;
            @media screen and (min-width: $small) {
                padding-right: 20px;
                padding-left: 0;
            }
        }
    }
}
