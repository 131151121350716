
$color-navy: #222E3B;
$color-light-yellow: #FFE9D0;
$color-orange: #F26703;

$color-dark-green: #0A8D5E;
$color-light-green: #B4E8C9;
$color-dark-blue: #08758a;
$color-light-blue: #add7de;
$color-dark-purple: #3c1e65;
$color-light-purple: #d3b6f9;
$color-dark-magenta: #853761;
$color-light-magenta: #ecd4dd;
$color-dark-red: #af3830;
$color-light-red: #e7c3c1;

$red: #D0021B;
$primary-color: $color-orange;
$light-grey: #C6C5BA;
$dark-grey: #63625C;
$grey: #f0f0f0;

$image-grayscale-pct: 30%;

$margin: 100px auto;
$padding: 100px 15px;


$small: 600px;
$medium: 992px;

$half-col: 6.25%;
$one-col: 12.5%;
$two-col: 25%;
$three-col: 37.5%;
$four-col: 50%;
$five-col: 62.5%;
$six-col: 75%;
$seven-col: 87.5%;
$eight-col: 100%;

.one-col { 
    width: 12.5%;
}

.two-col { 
    width: 25%;
}
.three-col { 
    width: 37.5%;
}
.four-col {
    width: 50%;
}
.five-col {
    width: 62.5%;
}
.six-col { 
    width: 75%;
}
.seven-col { 
    width: 87.5%;
}
.eight-col { 
    width: 100%;
}
