.cases {
    &.darkblue {
        background: $color-navy;
        .main-inner {
            margin: 0 auto;
            padding-top: 100px;
            padding-bottom: 100px;
            h2 {
                color: white;
                margin-bottom: 30px;
            }
            .swiper-pagination-bullet {
                background: white;
            }
        }
    }
    .main {
        position: relative;
    }
    .main-inner {
        display: flex;
        flex-wrap: wrap;
        padding: 0px $half-col;
        margin: $margin;
        @media screen and (min-width: $small) {
            padding: 0px $one-col;
        }
        h2 {
            width: 100%;
            text-align: center;
            margin: 0;
        }
        .swiper-container {
            padding-bottom: 20px;
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
            }
            .swiper-pagination-bullet-active,
            .swiper-pagination-bullet:hover {
                background: $primary-color;
                opacity: 1;
            }
            .swiper-container-horizontal>.swiper-pagination-bullets {
                bottom: 0;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            background: $color-navy;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: block;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (min-width: $small) {
                width: 40px;
                height: 40px;
                right: 20px;
            }
            &:after {
                color: white;
                font-size: 18px;
                left: 56%;
                top: 50%;
                position: absolute;
                transform: translateX(-50%) translateY(-50%);
                @media screen and (min-width: $small) {
                    font-size: 24px;
                }
            }
            &:hover {
                background: $primary-color;
            }
        }
        .swiper-button-prev {
            left: 5px;
            right: auto;
            @media screen and (min-width: $small) {
                left: 20px;
            }
            &:after {
                left: 45%;
            }
        }
        .one-card {
            width: 100%;
            padding: 30px 42px;
            border-radius: 12px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //border: 1px solid $primary-color;
            background: white;
            text-decoration: none;
            color: black;
            margin-bottom: 30px;
            @media screen and (min-width: $small) {
                padding: 30px;
            }
            img {
                background: $color-navy;
                height: 150px;
                width: 150px;
                object-fit: contain;
                margin-bottom: 36px;
            }
            h3 {
                border-bottom: 1px solid $primary-color;
                padding-bottom: 10px;
                margin-bottom: 12px;
                margin-top: 0;
            }
            p {
                max-width: 600px;
                &:first-of-type {
                    margin-top: 2px;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
