.contact-form {
    &.blue {
        background: $color-navy;
        color: white;
        padding-bottom: 100px;
        .form-container {
            padding: 0;
            margin-bottom: 0;
            .right .line {
                width: $three-col;
                position: absolute;
                right: 0;
            }
            .left form {
                input[type="text"],
                input[type="tel"],
                input[type="email"] {
                    background: white;
                    border: 1px solid white;
                }
            }
        }
        .right p {
            padding-top: 8px;
        }
    }
    .main {
        position: relative;
    }
    .main-inner {
        margin-left: 12.5%;
        margin-right: 12.5%;
        padding: 100px 0 30px;
        @media screen and (min-width: $small) {
            width: $four-col;
        }
        .content {
            padding-top: 20px;
            p:first-of-type {
                margin-top: 0;
            }
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
        position: absolute;
        margin-left: -$one-col;
    }
    .form-container {
        margin-left: 12.5%;
        margin-right: 12.5%;
        margin-bottom: 100px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width: $small) {
            padding: 26px 20px;
            background: $color-navy;
            color: white;
        }
        .testink {
            background-color: black;
            margin: 50px;
        }
        .left {
            width: 100%;
            @media screen and (min-width: $small) {
                width: 67%;
                padding-right: 10%;
            }
            form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                label {
                    letter-spacing: 1px;
                    font-size: 24px;
                    font-family: HelveticaNowText-Regular, sans-serif;
                    a {
                        font-size: 13px;
                    }
                    color: $color-navy;
                    @media screen and (min-width: $small) {
                        color: white;
                    }
                }
                .two-columns {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 1rem;
                    width: 100%;
                    font-weight: bold;
                }
                .underline {
                    text-decoration: underline;
                    cursor: pointer;
                }
                select,
                input,
                input[type="text"],
                input[type="tel"],
                input[type="email"] {
                    height: 38px;
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid $color-navy;
                    margin-bottom: 20px;
                    padding-left: 5px;
                    padding-top: 4px;
                    font-size: 15px;
                    font-family: HelveticaNowText-Regular;
                    background: $color-navy;
                    color: white;
                    @media screen and (min-width: $small) {
                        background: white;
                        color: $color-navy;
                        border: 1px solid white;
                    }
                }
                input:focus {
                    outline: none;
                }
                .error {
                    margin-top: 4px;
                    color: #a22020;
                }
            }
        }
        .right {
            text-align: right;
            margin-top: 70px;
            @media screen and (min-width: $small) {
                width: 33%;
                margin-top: 24px;
            }
            h3 {
                margin: 0;
                margin-bottom: 10px;
            }
            .line {
                position: relative;
                margin-right: -20px;
                margin-left: 0;
            }
            p {
                padding-top: 0px;
            }
        }
    }
}
