.text-boxes {

    h1 {
        @apply text-4xl lg:text-5xl xl:text-6xl;
    }

    h2 {
        @apply text-3xl lg:text-4xl xl:text-5xl;
    }

    h3 {
        @apply text-2xl lg:text-3xl xl:text-4xl;
    }

    .background-image {
        background-image: url(../../images/YSDS_pattern_navy02.png);
    }
    h2 {
        color: $color-navy;
    }

    .brandLifeScience & {
        background-color: $color-light-blue;
        h2 {
            color: $color-dark-blue;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkblue01.png);
        }
    }

    .brandArt & {
        background-color: $color-light-magenta;
        h2 {
            color: $color-dark-magenta;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkmagenta01.png);
        }
    }



    .brandSpecialLogistics & {
        background-color: $color-light-green;
        h2 {
            color: $color-dark-green;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkgreen01.png);
        }
    }

    .brandEPL & {
        background-color: $color-light-red;
        h2 {
            color: $color-dark-red;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkred01.png);
        }
    }

    .brandIT & {
        background-color: $color-light-green;
        h2 {
            color: $color-dark-green;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkgreen01.png);
        }
    }

    .text-box {
        position: relative;

        .background-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-position: top;
            background-size: cover;
        }
        .text-container {
            position: relative;
        }
    }
}

/* .text-boxes {
    position: relative;
    &.alternative {
        background: $color-navy;
        .main {
            color: white;
            .main-inner {
                margin: 0 auto;
                padding-top: 100px;
                padding-bottom: 100px;
            } 
        }
    }
    .background-image {
        background-image: url(../../images/YSDS_pattern_navy02.png);
    }
    .main-inner h2 {
        color: $color-navy;
    }

    .brandLifeScience & {
        .main-inner h2 {
            color: $color-dark-blue;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkblue02.png);
        }
    }

    .brandArt & {
        .main-inner h2 {
            color: $color-dark-magenta;
        }

        color: white;
        .background-image {
            background-image: url(../../images/YSDS_pattern_darkmagenta02.png);
        }
    }

    .triangle {
        background: $color-light-yellow;
        clip-path: polygon(0 0, -36% 0, 24.5% 100%, 0% 100%);
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: top;
        background-size: cover;
        filter: saturate(0.5);
    }

    .main {
        position: relative;
    }

    .main-inner {
        margin: $margin;
        padding: 0 $half-col;
        h2 {
            margin-bottom: 10px;
        }
        p {
            margin-left: auto;
            margin-right: auto;
            max-width: 820px;
        }
        .flex-container {
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -40px;
            justify-content: space-evenly;
            @media screen and (min-width: $small) {
                width: calc(100% + 30px);
            }
            @media screen and (min-width: $medium) {
                max-width: calc(100% + 50px);
            }
            .text-box {
                display: flex;
                width: 100%;
                margin-bottom: 40px;
                flex-direction: column;
                align-items: center;
                position: relative;
                padding: 2em;
                @media screen and (min-width: $small) {
                    max-width: calc(33.333% - 30px);
                    margin-right: 30px;
                }
                @media screen and (min-width: $medium) {
                    max-width: calc(33.333% - 50px);
                    margin-right: 50px;
                }
                .check-mark {
                    i {
                        font-size: 62px;
                        display: block;
                    }
                }
                .text-container {
                    position: relative;
                    h3 {
                        font-size: xxx-large;
                        margin: 0;
                        padding-bottom: 10px;
                        margin-bottom: 12px;
                    }
                    p:first-of-type {
                        margin-top: 5px;
                    }
                    p:last-of-type {
                        margin-bottom: 0;
                    }
                }

                &.inverted {
                    color: white;
                }

                &.dark {
                    .text-box .text-container h3 {
                        color: $primary-color;
                    }
                }

                &.align-left {
                    align-items: flex-start;
                    text-align: left;
                    .text-container {
                        padding-left: 0;
                    } 
                }
            }
            &.four {
                @media screen and (min-width: $small) {
                    width: calc(100% + 30px);
                }
                .text-box {
                    @media screen and (min-width: $small) {
                        max-width: calc(50% - 30px);
                        margin-right: 30px;
                    }
                    @media screen and (min-width: $medium) {
                        max-width: calc(25% - 30px);
                        margin-right: 30px;
                    }
                }
            }
        }
        .ysds-button {
            margin-top: 30px;
        }
    }
} */
