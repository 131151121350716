// 2022-10-06 | Some shopify stuff, we probably don't need it :)
// @import url("//hello.myfonts.net/count/3ea0af");

@font-face {
    font-family: "HelveticaNowText-Regular";
    src: url('../fonts/HelveticaNowTextRegular/font.woff2') format('woff2'), url('../fonts/HelveticaNowTextRegular/font.woff') format('woff');
}
@font-face {
    font-family: "HelveticaNowDisplay-XBd";
    src: url('../fonts/HelveticaNowDisplayXBd/font.woff2') format('woff2'), url('../fonts/HelveticaNowDisplayXBd/font.woff') format('woff');
}

@font-face {
    font-family: Helvetica-Neue-Condensed;
    src: url(../fonts/HelveticaNeueLTPro-BdCn.otf);
}

@font-face {
    font-family: Helvetica-Neue-Roman;
    src: url(../fonts/HelveticaNeueLTPro-Roman.otf);
}

@font-face {
    font-family: Helvetica-Neue-Bd;
    src: url(../fonts/HelveticaNeueLTPro-Bd.otf);
}

/** {
    font-family: sans-serif;
    font-family: Helvetica-Neue-Roman;
}*/

h1 {
    font-size: 38px;
    line-height: 108%;
    margin: 0;
    margin-bottom: 3px;
    font-family: HelveticaNowDisplay-XBd;
    font-weight: normal;
    @media screen and (min-width: $small) {
        font-size: 40px;
    }
}

h2 {
    font-size: 28px;
    line-height: 133%;
    margin: 0;
    margin-bottom: 6px;
    font-family: HelveticaNowDisplay-XBd;
    font-weight: normal;
    @media screen and (min-width: $small) {
        font-size: 30px;
    }
}

h3 {
    font-size: 24px;
    line-height: 120%;
    font-family: HelveticaNowDisplay-XBd;
    font-weight: normal;
}

h4 {
    margin: 0;
    font-family: HelveticaNowDisplay-XBd;
    font-weight: normal;
}

h5 {
    font-family: HelveticaNowDisplay-XBd;
    font-weight: normal;
}

p, a, li, div {
    font-weight: 200;
    font-size: 16px;
    line-height: 160%;
    font-family: HelveticaNowText-Regular;
}

strong {
    font-weight: bold;
}

a {
    color: $primary-color;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
/* 
ul, ol {
    padding-left: 20px;
    margin: 0;
}

ul {
    list-style: none;
    li::before {
        content: "\2022";
        color: $primary-color;
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
    }
}

ol li {
    margin-bottom: 10px;
} */

.lead {
    font-size: 1.4em;
}
