.error-page {
    .main-inner {
        margin: 100px auto 60px;
        h1 {
            text-align: center;
            color: $primary-color;
            font-size: 100px;
            @media screen and (min-width: $small) {
                font-size: 200px;
            }
        }
    }
}
