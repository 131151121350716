.blog-posts,
.latest-blog-posts {
    &.darkblue {
        background: $color-navy;
        .main-inner {
            margin: 0 auto;
            padding-top: 100px;
            padding-bottom: 100px;
            color: white;
            .one-post {
                &:first-of-type {
                    background: white;
                    color: black;
                    img {
                        display: block;
                    }
                    h3 {
                        font-size: 24px;
                    }
                    p {
                        font-size: 16px;
                    }
                    .date {
                        color: $dark-grey;
                    }
                }
            }
        }
    }
    .main {
        position: relative;
    }
    .main-inner {
        display: flex;
        flex-wrap: wrap;
        margin: $margin;
        justify-content: center;
        padding: 0 6.25%;
        @media screen and (min-width: $medium) {
            padding: 0 12.5%;
        }
        h2 {
            margin-bottom: 30px;
            text-align: center;
            width: 100%;
        }
        .flex-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
        }
        .one-post {
            margin-bottom: 20px;
            box-shadow: 0 0 13px 2px #0000001a;
            transition: all 0.2s ease;
            color: black;
            text-decoration: none;
            width: 100%;
            background: white;
            overflow: hidden;
            @media screen and (min-width: $small) {
                display: flex;
            }
            img {
                height: 250px;
                background: $color-navy;
                width: 100%;
                object-fit: cover;
                filter: grayscale(60%);
                display: none;
                @media screen and (min-width: $small) {
                    height: 180px;
                    width: 200px;
                }
                @media screen and (min-width: $medium) {
                    height: 100%;
                }
            }
            .post-text-container {
                position: relative;
                width: 100%;
                h3 {
                    border-bottom: 1px solid $primary-color;
                    padding-bottom: 5px;
                    margin: 20px 15px 10px;
                    font-size: 20px;
                    padding-right: 70px;
                }
                .date {
                    color: $dark-grey;
                    font-size: 13px;
                    font-family: HelveticaNowDisplay-XBd;
                    margin-top: 0;
                    position: absolute;
                    top: 1px;
                    right: 0;
                    @media screen and (min-width: $small) {
                        top: 20px;
                    }
                }
                p {
                    padding: 0 15px;
                    font-size: 14px;
                }
            }
            &:hover {
                box-shadow: 0 0 13px 2px #00000026;
            }
            &:first-of-type {
                background: $color-navy;
                color: white;
                img {
                    display: block;
                }
                h3 {
                    font-size: 24px;
                }
                p {
                    font-size: 16px;
                }
                .date {
                    color: #afafaf;
                }
            }
        }
        .pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            div {
                background: $color-navy;
                color: white;
                margin: 4px;
                padding: 7px 11px;
                line-height: 1;
                padding-top: 13px;
                font-family: HelveticaNowDisplay-XBd;
                &.active,
                &:hover {
                    background: $primary-color;
                }
            }
        }
    }
}
