.industry-banner {
  background-color: lighten(#000000, 10);
  color: #ffffff;
  padding: 3rem 0 5rem;
  @media screen and (min-width: $medium) {
    padding: 2rem 0;
  }
  .main-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (min-width: $medium) {
      align-items: center;
    }
  }

  .description {
    width: 100%;
    @media screen and (min-width: $medium) {
      width: 50%;
    }
    p {
      margin: 0;
    }
  }

  .form {
    width: 100%;
    height: 40px;
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: $medium) {
      width: calc(50% - 1rem);
      flex-wrap: nowrap;
      margin-left: 1rem;
      margin-top: 0;
    }

    .custom-select {
      position: relative;
      width: 100%;
      height: 35px;
      margin-bottom: 10px;
      @media screen and (min-width: $medium) {
        width: 40%;
        margin-right: 2rem;
        margin-bottom: 0;
      }

      &:after {
        content: '\25BE';
        display: block;
        position: absolute;
        top: 8px;
        right: 20px;
        color: #ffffff;
        font-size: 20px;
      }

      select {
        padding: 0 4rem 0 1rem;
        height: 100%;
        width: 100%;

        outline: none;
        border: #ffffff solid 1px;
        border-radius: 4px;
        background-color: rgba(#000000, 0.4);
        color: #ffffff;

        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
      }
    }


    button {
      &:first-of-type {
        margin-right: 4%;
      }
      width: 48%;
      @media screen and (min-width: $medium) {
        width: 30%;
      }
    }
  }
}