@import "~@fortawesome/fontawesome-free/css/all.css";
@import './mixins';
@import './variables';
@import './fonts';
@import './buttons';
@import "wordpress";

//@import './partials/hero';
@import './partials/hero-centered';
@import './partials/middle-hero';
@import './partials/numbers';
@import './partials/cards';
@import './partials/cases';
@import './partials/text-on-image';
@import './partials/contact-on-image';
@import './partials/text-and-image';
@import './partials/text-on-white';
@import './partials/black-diagonal';
@import './partials/contact-form';
@import './partials/footer';
@import './partials/offices';
@import './partials/agents';
@import './partials/simple-text';
@import './partials/article';
@import './partials/header';
@import './partials/tracker';
@import './partials/404';
@import './partials/check-list';
@import './partials/blog-posts';
@import './partials/shipping-form';
@import './partials/industry-banner';
@import './partials/breadcrumb';
@import './partials/country-dropdown';
@import './partials/text-boxes';

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    overflow-x: hidden;
    background: $color-navy;
}

.highblack {
    background: $color-navy;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

body {
    margin: 0;
    overflow-x: hidden;
    padding-top: 70px;
    background: white;
}

.main {
    width: 100%;
}

.main-inner {
    //max-width: 1100px;
    margin: 0 auto;
    padding: 0 $one-col;

    //Fix so that images doesn't overflow container - Joakim
    img {
        max-width: 100%;
        height: auto;
    }
}

img {
    display: block;
}


/* Checkbox */
.checkbox-label {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover input ~ .checkmark {
        background-color: $primary-color;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: $primary-color;
        }
        &:checked ~ .checkmark:after {
            display: block;
        }
    }
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: 1px solid $primary-color;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
        &:after {
            left: 4px;
            top: 1px;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}