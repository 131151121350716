.header {
    background: white;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    .nav {
        display: flex;
        padding: 0 15px;
        height: 70px;

        .logo {
            height: 70px;
            width: auto;
        }

        .ysds-button {
            &:hover {
                text-decoration: underline;
            }
        }

        .menu-container.desktop {
            height: 100%;
            width: 100%;
            padding: 0 Min(100px, auto);
            display: none;
            @media screen and (min-width: $medium) {
                display: flex;
                column-gap: 0.5vw;
            }
            margin: auto;
            justify-content: center;

            li {
                height: 100%;

                &.one-submenu {
                    position: relative;
                    .sub-menu {
                        top: 70px;
                        padding: 10px 20px;
                        position: absolute;
                        width: auto;
                        left: -10px;

                        .sub-menu-list {
                            li a {
                                letter-spacing: 1.5px;
                                white-space: nowrap;
                                margin: 6px 0;
                                font-size: 13px;
                            }
                        }
                    }
                }

                &:before {
                    display: none;
                }

                a {
                    text-decoration: none;
                    color: $color-navy;
                    letter-spacing: 1.5px;
                    font-size: 16px;
                    display: block;
                    margin: 0 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;
                    font-family: HelveticaNowText-Regular, sans-serif;
                }

                &:hover a {
                    text-decoration: underline;
                }

                &.has-children:hover {
                    > a {
                        &:after {
                            display: block;
                        }
                    }

                    .sub-menu {
                        display: flex;
                    }
                }

                .sub-menu {
                    width: 100%;
                    position: absolute;
                    background: white;
                    left: 0;
                    padding: 24px;
                    display: flex;
                    justify-content: center;
                    border-top: 1px solid $primary-color;
                    display: none;

                    .sub-menu-list {
                        margin: 0 20px;
                    }

                    h5 {
                        margin: 0;
                        border-bottom: 1px solid white;
                        font-size: 14px;
                        letter-spacing: 1.5px;
                    }

                    ul {
                        padding: 0;
                        padding-top: 6px;

                        li a {
                            margin: 6px 0;
                            text-transform: none;
                            font-size: 13px;
                            text-decoration: none;
                            font-family: HelveticaNowText-Regular, sans-serif;

                            &:after {
                                display: none;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .menu-container.mobile {

            position: absolute;
            top: 70px;
            left: 100%;
            background: #131313;
            color: white;
            width: 100%;
            padding: 50px 20px;
            height: calc(100vh - 70px);
            overflow-y: scroll;
            transition: all 0.2s ease;

            &.show {
                left: 0;
            }

            @media screen and (min-width: $medium) {
                display: none;
            }

            ul {
                padding: 0;
            }

            li {
                margin: 16px 0;

                &:before {
                    display: none;
                }

                a {
                    font-family: HelveticaNowText-Regular, sans-serif;
                }

                &.has-children {
                    position: relative;

                    &.show {
                        &:after {
                            content: '-';
                        }
                    }

                    &:after {
                        pointer-events: none;
                        content: '+';
                        width: 15px;
                        text-align: center;
                        position: absolute;
                        color: $primary-color;
                        font-size: 27px;
                        margin-left: 10px;
                        right: 0;
                        top: 4px;
                        font-family: HelveticaNowText-Regular, sans-serif;
                    }

                    a {
                        display: inline-block;
                        margin: 0 0;

                    }

                    ul {
                        a {
                            display: block;
                            margin: 17px 0;
                            font-family: HelveticaNowText-Regular, sans-serif;
                        }
                    }

                    span {
                        display: block;
                    }

                }

                a {
                    color: white;
                    text-decoration: none;
                    font-size: 21px;
                    margin: 17px 0;
                    display: block;
                }

                .sub-menu {
                    padding-left: 10px;
                    display: none;

                    &.show {
                        display: block;
                    }

                    .sub-menu-list {
                        margin-bottom: 30px;

                        li a {
                            font-size: 15px;

                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            h5 {
                margin: 0;
                border-bottom: 1px solid white;
                font-size: 14px;
                letter-spacing: 1.5px;
                font-family: HelveticaNowText-Regular, sans-serif;
                margin-top: 30px;
            }
        }

        .ysds-button {
            align-self: center;
            margin-left: auto;
            margin-right: 2rem;
            display: none;

            @media screen and (min-width: $medium) {
                display: block;
            }
        }

        // .header-contact-form {
        //     display: none;
        //     @media screen and (min-width: $medium) {
        //         display: block;
        //         position: absolute;
        //         top: 70px;
        //         right: 0;
        //         background: $color-navy;
        //         color: white;
        //         padding: 30px 25px;
        //         width: 430px;
        //         border-radius: 0px 0px 0px 10px;
        //         h4 {
        //             font-weight: 900;
        //             font-size: 20px;
        //             line-height: 120%;
        //             margin-bottom: 12px;
        //         }
        //         form {
        //             display: flex;
        //             flex-direction: column;
        //             align-items: flex-start;

        //             label {
        //                 letter-spacing: 1px;
        //                 font-size: 12px;
        //                 font-family: HelveticaNowText-Regular, sans-serif;
        //                 a {
        //                     font-size: 13px;
        //                 }
        //             }

        //             input {
        //                 color: $color-navy;
        //             }
        //             .details {
        //                 padding: 16px 0;
        //             }
        //             .two-columns {
        //                 display: grid;
        //                 grid-template-columns: 1fr 1fr;
        //                 grid-column-gap: 1rem;
        //                 width: 100%;
        //                 font-weight: bold;
        //             }
        //             .underline {
        //                 text-decoration: underline;
        //                 cursor: pointer;
        //             }

        //             input[type=text],
        //             input[type=tel],
        //             input[type=email] {
        //                 height: 38px;
        //                 width: 100%;
        //                 border-radius: 3px;
        //                 border: 1px solid white;
        //                 margin-bottom: 20px;
        //                 padding-left: 5px;
        //                 padding-top: 4px;
        //                 font-size: 15px;
        //                 font-family: HelveticaNowText-Regular, sans-serif;
        //             }

        //             input:focus {
        //                 outline: none;
        //             }
        //             .error {
        //                 margin-top: 0;
        //                 color: #db3d3d;
        //             }
        //         }
        //     }
        // }

        .hamburger {

            width: 40px;
            height: 34px;
            position: absolute;
            top: 18px;
            right: 15px;
            background-image: url(../../images/hamburger-dark.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            @media screen and (min-width: $medium) {
                display: none;
            }
            appearance: none;
            border: none;
            background-color: transparent;

        }

        .navopen {
            background-image: url(../../images/close-dark.png);

        }
    }
}
