.article {
    position: relative;
    min-height: 90vh;
    &.darkblue {
        .triangle {
            background: $color-navy;
        }
        .main {
            background: $color-navy;
            @media screen and (min-width: $small) {
                background-color: rgba(255, 255, 255, 0.7);
            }
        }
        .main-inner {
            margin: 0 auto;
            padding-top: 100px;
            padding-bottom: 100px;
            color: white;
        }
    }
    .background-image {
        width: 100%;
        height: 250px;
        position: relative;
        top: 0;
        left: 0;
        background-image: url(../../images/box.jpg);
        background-position: top;
        background-size: cover;
        filter: saturate(0.5);
        @media screen and (min-width: $small) {
            position: absolute;
            width: 40%;
            height: 100%;
        }
    }
    .main {
        position: relative;
        @media screen and (min-width: $small) {
            background-color: rgba(0,0,0,0.7);
        }
    }
    .triangle {
        display: none;
        @media screen and (min-width: $small) {
            display: block;
            background: white;
            clip-path: polygon(25% 0, 100% 0, 100% 100%, 37.5% 100%);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
        }
    }
    .main-inner {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        @media screen and (min-width: $small) {
            padding-top: 150px;
            padding-bottom: 150px;
        }
        .text-container {
            margin-left: 12.5%;
            margin-right: 12.5%;
            @media screen and (min-width: $small) {
                padding-left: 30px;
                margin-left: $three-col;
                width: $four-col;
                padding-left: 10px;
            }
        }
        h2 {
            font-size: 17px;
            font-family: HelveticaNowText-Regular;
            margin-top: 21px;
            line-height: 150%;
        }
        .content {
            margin-top: 50px;
        }
        .ysds-button {
            margin-top: 50px;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
        margin-left: -40%;
        position: absolute;
    }
}
