.hero-centered {
    position: relative;
    &.darkblue {
        .main {
            background-color: rgba(0,0,0,0.7);
            .main-inner {
                color: white;
            } 
        }
        .triangle {
            background: $color-navy;
        }
    }
    &.hide-arrow {
        .bouncing-arrow {
            display: none;
        }
    }
    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: top;
        background-size: cover;
        filter: saturate(0.5);
    }
    .main {
        position: relative;
        background-color: rgba(255, 255, 255, 0.7);
    }
    .main-inner {
        padding: 150px 0;
        margin: 0 auto;
        text-align: center;
        @media screen and (min-width: $small) {
            width: $four-col;
        }
        .text-container {
            position: relative;
            padding: 0px 6.5%;
            @media screen and (min-width: $small) {
                padding: 0;
            }
        }
        h1 {
            position: relative;
            margin-bottom: -4px;
            @media screen and (min-width: $small) {
                font-size: 60px;
                line-height: 108%;
            }
        }
        .logo-image {
            display: block;
            margin: 0 auto 12px;
            max-height: 21em;
            max-height: 20vw;
            max-width: 40em;
        }
        h2 {
            font-size: 21px;
            font-family: HelveticaNowText-Regular;
            margin-top: 12px;
            line-height: 143%;
            position: relative;
            text-transform: none;
            font-weight: 100;
        }
        .ysds-button {
            margin-top: 20px;
            position: relative;
        }
        .bouncing-arrow {
            appearance: none;
            background: none;
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            animation-name: bounce;
            animation-iteration-count: infinite;
            animation-duration: 2s;
            background-image: url(../../images/arrow.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border: none;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
        display: inline-block;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-10px);
    }
    60% {
      transform: translateX(-50%) translateY(-5px);
    }
  }
