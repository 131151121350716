.footer {
    background: $color-navy;
    padding-top: 50px;
    padding-bottom: 50px;
    .main-inner {
        display: flex;
        flex-wrap: wrap;
        color: white;
        @media screen and (min-width: $small) {
            padding: 0 15px;
        }
        @media screen and (min-width: $medium) {
            padding: 0 $one-col;
        }
        img {
            object-fit: contain;
            object-position: left;
            width: 200px;
            height: 137px;
            margin-bottom: 30px;
            @media screen and (min-width: $small) {
                margin-right: 30px;
            }
        }
        ul {
            list-style-type: none;
            margin-bottom: 30px;
            width: 100%;
            padding: 0;
            &:last-of-type {
                margin-bottom: 0;
            }
            @media screen and (min-width: $small) {
                width: auto;
                margin-right: 30px;
                margin-top: 25px;
            }
            li {
                &:before {
                    display: none;
                }
                a {
                    color: inherit;
                    font-size: inherit;
                    text-decoration: none;
                }
            }
        }
    }
}

