.numbers {
    &.darkblue {
        .main {
            background-color: $color-navy;
            .main-inner {
                color: white;
                padding: 100px 0;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .main {
        position: relative;

    }
    .main-inner {
        width: $six-col;
        margin-left: 12.5%;
        padding:100px 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 100px;
        margin-top: 100px;
        .content {
            padding-top: 30px;
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .one-number {
            text-align: center;
            margin-bottom: 30px;
            margin-top: 40px;
            width: 100%;
            &:last-of-type {
                margin-bottom: 0;
            }
            @media screen and (min-width: $small) {
                width: 30%;
                margin-bottom: 0;
            }
           .big-number {
               font-size: 60px;
               line-height: 1;
               margin: 0;
               font-family: Helvetica-Neue-Bd;
               @media screen and (min-width: $small) {
                   font-size: 60px;
               }
               @media screen and (min-width: $medium) {
                    font-size: 90px;
                }
           }
           .explanation {
            color: #9F9F9F;
            font-family: Helvetica-Neue-Bd;
            letter-spacing: 1.5px;
            line-height: 130%;
            font-size: 14px;
           }
        }
        .ysds-button {
            margin-top: 20px;
            position: absolute;
            bottom: 100px;
            right: $one-col;
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
        margin-left: -$one-col;
        position: absolute;
    }
}
