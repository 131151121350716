.shipping-form {
    background: $color-navy;
    color: white;
    padding: 100px 0;

    .main-inner {
        padding: 0 6.25%;
        @media screen and (min-width: $small) {
            padding: 0 12.5%;
        }

        label {
            width: 100%;
            display: block;
        }

        label.radio-label,
        label.form-control {
            font-weight: normal;
            margin-top: 5px;

            span {
                font-family: HelveticaNowText-Regular;
                margin-left: 8px;
            }
        }

        input[type=text],
        input[type=tel],
        input[type=email],
        select {
            height: 38px;
            width: 100%;
            border-radius: 3px;
            padding-left: 9px;
            padding-top: 6px;
            font-size: 15px;
            font-family: HelveticaNowText-Regular;
            border: 1px solid #dedede;
            background: white;
        }

        textarea {
            width: 100%;
            border-radius: 3px;
            padding-left: 9px;
            padding-top: 10px;
            font-size: 15px;
            font-family: HelveticaNowText-Regular;
            border: 1px solid #dedede;
            background: white;
            display: block;
        }

        input[type=file] {
            font-family: HelveticaNowText-Regular;
            margin-top: 5px;
        }

        input:focus {
            outline: none;
        }

        .form-section {
            background: #f8f8f8;
            color: black;
            border-radius: 3px;
            padding: 30px 15px;
            margin-bottom: 30px;
            @media screen and (min-width: $small) {
                padding: 50px 30px;
            }

            fieldset {
                margin: 0;
                border: 0;

                .form-row.addon {
                    margin-bottom: 8px;
                    @media screen and (min-width: $small) {
                        display: inline-block;
                        width: calc(100% - 188px);
                        margin-right: 8px;
                        margin-bottom: 0;
                    }

                    & + .form-row {
                        @media screen and (min-width: $small) {
                            display: inline-block;
                            width: 180px;
                        }

                        label {
                            display: none;
                        }
                    }
                }

                .form-row {
                    &.hide {
                        display: none;
                    }
                }
            }

            h4 {
                font-size: 24px;
                border-bottom: 1px solid $primary-color;
            }

            ul.errors {
                margin-top: 0.5em;
                padding-left: 0;
                li {
                    background-color: lighten($red, 25%);
                    border-radius: 0.5em;
                    color: darken($red,25%);
                    display: inline-block;
                    font-size: small;
                    padding: 0.3em 0.5em 0;
                    &:before {
                        content: "";
                    }
                }
            }
        }

    }
}
