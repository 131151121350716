@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .hero {
        @apply bg-navy text-white 
    }
    .hero-image {
        height: 30vh !important;
        @apply h-new bg-cover;
    }

    .hero .content {
        @apply px-4;
    }

    .hero .title {
        @apply text-light-yellow text-3xl leading-none mt-8;
        @apply lg:-mt-word lg:text-7xl;
    }

    .hero .title span {
        @apply lg:block
    }
    .hero .title span:first-child {
        @apply text-white;
    }

    .brandArt .hero {
        @apply bg-magenta-dark;
    }
    .brandArt .hero .title {
        @apply text-magenta-light;
    }
    .brandArt .hero .title span:first-child {
        @apply lg:text-magenta-dark;
    }

    .brandArt .ysds-button {
        @apply bg-magenta-light border-magenta-light text-magenta-dark;
    }



    .brandLifeScience .hero {
        @apply bg-blue-dark;
    }
    .brandLifeScience .hero .title {
        @apply text-blue-light;
    }

    .brandLifeScience .hero .title span:first-child {
        @apply lg:text-blue-dark;
    }

    .brandLifeScience .ysds-button {
        @apply bg-blue-light border-blue-light text-blue-dark;
    }


    .brandSpecialLogistics .hero {
        @apply bg-green-dark;
    }
    .brandSpecialLogistics .hero .title {
        @apply text-green-light;
    }

    .brandSpecialLogistics .hero .title span:first-child {
        @apply lg:text-green-dark;
    }

    .brandSpecialLogistics .ysds-button {
        @apply bg-green-light border-green-light text-green-dark;
    }


    .brandEPL .hero {
        @apply bg-red-dark;
    }

    .brandEPL .hero .title {
        @apply text-red-light;
    }

    .brandEPL .hero .title span:first-child {
        @apply lg:text-red-dark;
    }

    .brandEPL .ysds-button {
        @apply bg-red-light border-red-light text-red-dark;
    }

    .brandIT .hero {
        @apply bg-green-dark;
    }
    .brandIT .hero .title {
        @apply text-green-light;
    }

    .brandIT .hero .title span:first-child {
        @apply lg:text-green-dark;
    }

    .brandIT .ysds-button {
        @apply bg-green-light border-green-light text-green-dark;
    }


    .heading1 {
        @apply text-4xl lg:text-5xl xl:text-6xl;
    }

    .heading2 {
        @apply text-3xl lg:text-4xl xl:text-5xl;
    }

    .heading3 {
        @apply text-2xl lg:text-3xl xl:text-4xl;
    }

    .text-container p {
        @apply my-4;
    }
    .text-container ul {
        @apply list-disc ml-6;
    }

    .text-container ol {
        @apply list-decimal ml-6;
    }
}
