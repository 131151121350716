.contact-on-image {
    position: relative;
    &.dark {
        .main {
            background-color: rgba(0,0,0,0.7);
            .triangle {
                background: $color-navy;
            }
            .main-inner {
                color: white;
                .form-container {
                    @media screen and (min-width: $small) {
                        background: white;
                        color: black;
                    }
                    input[type=text],
                    input[type=email],
                    input[type=tel] {
                        background: white;
                        border: 1px solid white;
                        @media screen and (min-width: $small) {
                            border: 1px solid $color-navy;
                        }
                    }
                }
            }
        }
    }
    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(../../images/box.jpg);
        background-position: top;
        background-size: cover;
        filter: saturate(0.5);
    }
    .main {
        position: relative;
        background-color: rgba(255,255,255,0.7);
    }
    .triangle {
        background: white;
        clip-path: polygon(0 0, 25% 0, 37.5% 100%, 0% 100%);
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .main-inner {
        margin-left: 12.5%;
        margin-right: 12.5%;
        padding:100px 0 100px;
        @media screen and (min-width: $small) {
            width: $four-col;
            padding:100px 0 150px;
        }
        p {
            padding-top: 8px;
        }
        .content {
            padding-top: 20px;
            position: relative;
            .form-container {
                border-radius: 10px;
                @media screen and (min-width: $small) {
                    padding: 35px 28px;
                    background: $color-navy;
                    color: white;
                }
                h3 {
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .two-columns {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-column-gap: 1rem;
                        width: 100%;
                        font-weight: bold;
                    }
                    .underline {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    label {
                        letter-spacing: 1px;
                        font-size: 12px;
                        font-family: HelveticaNowText-Regular;
                    }

                    input {
                        color: $color-navy;
                    }
                    input[type=text],
                    input[type=tel],
                    input[type=email] {
                        height: 38px;
                        width: 100%;
                        border-radius: 3px;
                        border: 1px solid $dark-grey;
                        background: $grey;
                        margin-bottom: 20px;
                        padding-left: 5px;
                        padding-top: 4px;
                        font-size: 15px;
                        font-family: HelveticaNowText-Regular;
                        @media screen and (min-width: $small) {
                            border: 1px solid white;
                            background: white;
                        }
                    }
                    input:focus {
                        outline: none;
                    }
                }
            }
        }
        h2, p {
           position: relative;
        }
    }
    .line {
        height: 1px;
        background: $primary-color;
        margin-left: -$one-col;
        position: absolute;
    }
}
